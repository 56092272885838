<template>
  <div class="brandStorySubmission">
    <LincolnEffortlessExperienceLogoDark class="logo" />
    <div class="form-title">Submit Your Ideas - Submission Form</div>
    <p>
      <span class="directions">Directions:</span>
      Please complete all of the fields below and add any relevant file attachments
      that support your idea, including photos. When ready, click "Send Now" and your
      submission will be forwarded for review. You may be contacted if any additional
      information or clarification is needed. Should your submission be published,
      you will find it in the "TOP POSTS BY" section of your profile. If you need
      assistance completing this form, you may contact Program Headquarters at
      <a :href="supportEmailHref">{{supportEmail}}</a>.
    </p>

    <form @submit.prevent="submit">
      <TextBox :label="'First Name'"
               :name="'firstName'"
               :placeholder="'First Name'"
               v-model="user.nameFirst"
               :disabled="true"
               class="textBox" />
      <TextBox :label="'Last Name'"
               :name="'lastName'"
               :placeholder="'Last Name'"
               v-model="user.nameLast"
               :disabled="true"
               class="textBox" />
      <TextBox v-show="user.dealership"
               :label="'Dealership'"
               :name="'dealership'"
               :placeholder="'Dealership'"
               v-model="user.dealership"
               :disabled="true"
               class="textBox" />
      <TextBox :label="'Subject'"
               :name="'subject'"
               :required="true"
               :placeholder="'Subject *'"
               v-model="brandStory.subject"
               class="textBox" />
      <div class="required">Fields marked with * are required.</div>
      <BusinessAreaOption v-for="(area) in this.businessAreas"
                          :key="area.id"
                          :label="area.name"
                          :name="businessAreasName"
                          :value="area.id"
                          @updateSelectedAreas="updateSelectedAreas"
      />
      <div class="required">Please select one.</div>
      <div class="tenet">
        Effortless Tenet
        <div class="arrowDown"></div>
      </div>
      <div class="tenetContainer">
        <EffortlessTenetOption v-for="(tenet) in this.tenets"
                               :key="tenet.id"
                               :label="tenet.name"
                               :name="effortlessTenetsName"
                               :value="tenet.id"
                               @updateSelectedTenets="updateSelectedTenets"
        />
      </div>
      <TextBox :name="'body'"
               :required="true"
               :type="'textarea'"
               :placeholder="bodyText"
               v-model="brandStory.body"
               class="textBox details" />
      <div class="required" v-if="attachments.length == 0">
        Include attachments to support your idea, such as photos, documents, or videos.
        Earn 10 extra points for your original photos!
      </div>
      <MultipleFiles
                     v-model="attachments"
                     :name="attachments"
                     :copy="'Upload Photos&nbsp;/&nbsp;Files&nbsp;/&nbsp;Videos'"/>
      <ButtonSubmit
        :copy="'Send Now'"
        @callback="submit"
      />
      <ButtonCancel class="cancelButton"
                    @click="closeModal" />
    </form>
  </div>
</template>

<script>
import TextBox from '../../formElements/TextBox.vue';
import BusinessAreaOption from './BusinessAreaOption.vue';
import EffortlessTenetOption from './EffortlessTenetOption.vue';
import LincolnEffortlessExperienceLogoDark from '../../LincolnEffortlessExperienceLogoDark.vue';
import MultipleFiles from '../../formElements/MultipleFiles.vue';
import ButtonSubmit from '../../formElements/buttons/buttonSubmit.vue';
import ButtonCancel from '../../formElements/buttons/ButtonCancel.vue';

const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;

export default {
  name: 'BrandStorySubmission',
  components: {
    TextBox,
    BusinessAreaOption,
    EffortlessTenetOption,
    LincolnEffortlessExperienceLogoDark,
    MultipleFiles,
    ButtonSubmit,
    ButtonCancel,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      supportEmail,
      supportEmailHref: `mailto:${supportEmail}?subject=Brand Story submission form"`,
      bodyText: 'Please include detailed steps of how you implemented this tactic '
        + 'in your Dealership. Also include measurable outcomes that illustrate how '
        + 'the implementation of this tactic positively contributed to your business.',
      businessAreasName: 'businessAreas',
      effortlessTenetsName: 'effortlessTenets',
      attachments: [],
      brandStory: {
        businessAreas: [],
        effortlessTenets: [],
        attachments: [],
        subject: '',
        body: '',
      },
    };
  },
  computed: {
    tenets() {
      return this.$store.getters['tenetStore/effortlessTenets'];
    },
    businessAreas() {
      return this.$store.getters['businessAreaStore/businessAreas'];
    },
    user() {
      return this.$store.getters['userStore/profile'];
    },
  },
  methods: {
    deleteAsset(attachmentIndex) {
      if (this.attachments.length > 0) {
        this.attachments.splice(attachmentIndex, 1);
      }
    },
    async submit(event) {
      if (!event.target.form.reportValidity()) {
        this.$store.dispatch('buttonSubmitStore/done');
        return;
      }

      if (this.attachments.length > 0) {
        const formDataFile = new FormData();
        [...this.attachments].forEach((v) => {
          formDataFile.append('files', v);
        });
        const assetResp = await this.$store.dispatch('assetStore/setMultiAsset', formDataFile);
        if (assetResp != null) {
          [...assetResp].forEach((v) => {
            this.brandStory.attachments.push({ attachmentassetid: v.id });
          });
        }
      }

      await this.$store.dispatch('brandStoryStore/submitBrandStory', this.brandStory);

      this.$analytics.trackEventBtnPage('BrandStorySubmit');

      this.closeModal();
      this.$store.dispatch('buttonSubmitStore/done');

      this.$dialog
        .confirm('Thank you for your submission. It has been received and is under review.', {
          html: false,
          okText: 'OK',
          cancelText: null,
          customClass: 'hideCancel',
        });
    },
    closeModal() {
      this.$modal.hide(this.name);
    },
    updateSelectedTenets(effortlessObject) {
      const indexOfTenet = this.brandStory
        .effortlessTenets.map(tenet => tenet.id).indexOf(effortlessObject.id);

      if (indexOfTenet !== -1) {
        this.brandStory
          .effortlessTenets.splice(indexOfTenet, 1);
      } else {
        this.brandStory.effortlessTenets.push(effortlessObject);
      }
    },
    updateSelectedAreas(areaObject) {
      const indexOfArea = this.brandStory
        .businessAreas.map(tenet => tenet.id).indexOf(areaObject.id);

      if (indexOfArea !== -1) {
        this.brandStory
          .businessAreas.splice(indexOfArea, 1);
      } else {
        this.brandStory.businessAreas.push(areaObject);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .brandStorySubmission {
    padding: 2em;
    background-color: white;

    @include respond(md) {
      padding: 4em;
    }

    .logo {
      margin-bottom: 75px;
    }

    .form-title {
      @include font-size(2rem);
    }

    .directions {
      font-weight: bold;
    }
  }
  .required {
    color: $LincolnWarn;
  }

  .options {
    color: $LincolnGrayLight;
  }

  .textBox {
    margin: 2em 0;
    display: block;

    &.details {
     /deep/ textarea {
        height: 125px;
      }
    }
  }

  .tenet {
    background-color: $LincolnGrayLight;
    color: $LincolnGrayDark;
    width: 15%;
    text-transform: uppercase;
    padding: 1em;
    margin: 0.1em;
  }

  $arrowWidth: 0.7em;
  .arrowDown {
    display: inline-block;
    width: 0;
    height: 0;
    overflow: hidden;
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-top: $arrowWidth solid $LincolnOrange;
  }

  .tenetContainer {
    display: inline-flex;
  }

  .cancelButton {
    margin-left: 1rem;
  }

  .hideCancel {
    .dg-btn--cancel {
      display: none;
    }

    .dg-content {
      word-wrap: break-word;

      a {
        color: $LincolnOrange;
      }
    }
  }
</style>
